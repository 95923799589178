import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View, Image, Platform, useWindowDimensions, Touchable } from 'react-native';
import Label from '../../common/Label';
import { map as Map } from '../../../models/helpers/platformImportHelper';
import { MapLocation, MapMarker } from '../../../models/MapModels';
import Constants from 'expo-constants';
import useCustomTheme from '../../../hooks/theme-hook';
import CustomButton from '../../common/CustomButton';
import { useQuery } from '@tanstack/react-query';
import useApi from '../../../hooks/api-hook';
import { MyMissionClient, PreValidationDto } from '../../../api/api.g';
import Loading from '../../common/Loading';
import { MissionsHelper } from '../../../models/helpers/missionsHelper';
import useTranslation from '../../../hooks/translation-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import { StringHelper } from '../../../helpers/stringHelper';

export interface OnboardingProps {
  selectedMissionId: string;
  onClosePress?(): void;
  onConfirmPress?(): void;
  refreshMap?: boolean;
  onMapRefreshed?(): void;
}

const Prevalidation = (props: OnboardingProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((selector) => selector.auth);
  const defaultPosition: MapLocation = Constants.expoConfig.extra.defautlLocation;
  const apiMyMission = useApi(MyMissionClient);
  const layout = useWindowDimensions();
  const scrollViewRef = useRef(null);
  const [flashCount, setFlashCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [preValidationDto, setPreValidationDto] = useState<PreValidationDto>();
  const [location, setLocation] = useState<MapLocation>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const dimensions = useWindowDimensions();
  const [refreshMap, setRefreshMap] = useState(false);

  const getPreValidationQuery = useQuery({
    enabled: !!props.selectedMissionId && !!authStore.idToken,
    queryKey: ['getPreValidationQuery', props.selectedMissionId],
    queryFn: () => {
      setLoading(true);
      return apiMyMission.getPreValidation(props.selectedMissionId, apiMyMission.tokenSource?.token);
    },
  });

  useEffect(() => {
    setRefreshMap(props.refreshMap);
  }, [props.refreshMap]);

  useEffect(() => {
    if (getPreValidationQuery.isSuccess) {
      setLoading(false);
      const data = getPreValidationQuery?.data?.preValidation;

      if (data) {
        setPreValidationDto(data);

        setLocation({
          lat: data?.branchGeoLocation?.y,
          lng: data?.branchGeoLocation?.x,
        });

        const { icon, faIcon } = MissionsHelper.getMissionIcon();

        setMarkers([
          {
            id: data?.branchId,
            selected: true,
            coordinate: { latitude: data?.branchGeoLocation?.y ?? defaultPosition.lat, longitude: data?.branchGeoLocation?.x ?? defaultPosition.lng },
            svgIcon: icon,
            faIcon,
            seed: StringHelper.generateRandomString(),
            defaultPin: 'default',
          },
        ]);
      } else {
        // In case the pre validation is empty
        handleOnConfirmPress();
      }
    }
  }, [getPreValidationQuery.isSuccess, getPreValidationQuery.data]);

  const handleOnClosePress = () => {
    props.onClosePress && props.onClosePress();
  };

  const handleOnConfirmPress = () => {
    props.onConfirmPress && props.onConfirmPress();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollViewRef.current && flashCount < 3) {
        scrollViewRef.current.flashScrollIndicators();
        setFlashCount((prevCount) => prevCount + 1);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [flashCount]);

  return (
    <ScrollView ref={scrollViewRef} style={{ flexGrow: 0, width: dimensions.width < 550 ? 350 : 500 }}>
      {!loading && preValidationDto ? (
        <View style={{ paddingHorizontal: 18, paddingVertical: 18 }}>
          <View>
            {location.lat && location.lng && (
              <>
                <View>
                  <Label numberOfLines={10} style={{ fontSize: 18, color: theme.text.darkTurquoiseTwo, fontWeight: '600' }}>
                    {t('Did-you-successfully-complete-your-mission-at-the')}
                  </Label>
                </View>
                {preValidationDto.address && (
                  <Label
                    numberOfLines={10}
                    style={{
                      fontSize: 16,
                      color: theme.text.primary,
                      fontWeight: '400',
                      marginTop: 8,
                      marginBottom: 16,
                      maxWidth: Platform.OS === 'web' ? 320 : 200,
                    }}
                  >
                    {preValidationDto.address}
                  </Label>
                )}
                <View
                  style={{
                    width: '100%',
                    height: layout.width < 700 ? 175 : 300,
                    borderWidth: 1,
                    borderColor: theme.borderColor,
                    ...StylesHelper.borderRadius(8),
                    overflow: 'hidden',
                  }}
                >
                  <Map
                    refreshMap={refreshMap}
                    onMapRefreshed={() => {                      
                      setRefreshMap(false);
                      props.onMapRefreshed && props.onMapRefreshed();
                    }}
                    hidePanToOrigin
                    referencePosition={location}
                    markerItems={markers}
                    zoom={12}
                    mapId="pre-validation-map"
                  />
                </View>
              </>
            )}
            {preValidationDto.time && (
              <>
                <Label
                  numberOfLines={10}
                  style={{ fontSize: 18, color: theme.text.darkTurquoiseTwo, fontWeight: '600', marginTop: location.lat && location.lng ? 24 : 0 }}
                >
                  {t('Was-it-completed-on-these-days')}
                </Label>
                <Label numberOfLines={10} style={{ fontSize: 16, color: theme.text.primary, fontWeight: '400' }}>
                  {preValidationDto.time}
                </Label>
              </>
            )}

            {preValidationDto.supportedCultureCodes && (
              <>
                <Label
                  numberOfLines={10}
                  style={{ fontSize: 18, color: theme.text.darkTurquoiseTwo, fontWeight: '600', marginTop: location.lat && location.lng ? 24 : 0 }}
                >
                  {t('Was-it-completed-in-these-languages')}
                </Label>
                <Label numberOfLines={10} style={{ fontSize: 16, color: theme.text.primary, fontWeight: '400' }}>
                  {preValidationDto.supportedCultureCodes.map((x) => t(x)).join(', ')}
                </Label>
              </>
            )}
            {preValidationDto.purchaseRequiredText && preValidationDto.purchaseRequired && (
              <>
                <Label
                  numberOfLines={10}
                  style={{ fontSize: 18, color: theme.text.darkTurquoiseTwo, fontWeight: '600', marginTop: location.lat && location.lng ? 24 : 0 }}
                >
                  {t('purchase_required')}
                </Label>
                <Label numberOfLines={10} style={{ fontSize: 16, color: theme.text.primary, fontWeight: '400' }}>
                  {preValidationDto.purchaseRequiredText}
                </Label>
              </>
            )}
          </View>
          <View style={{ alignItems: 'center' }}>
            <View style={{ margin: 'auto', flexDirection: 'row', marginTop: 24 }}>
              <CustomButton
                title={t('yes')}
                onPress={handleOnConfirmPress}
                style={{ borderColor: theme.text.darkTurquoiseTwo, backgroundColor: theme.text.darkTurquoiseTwo }}
                textStyle={{ color: 'white' }}
              />
              <CustomButton
                secondary
                title={t('no')}
                onPress={handleOnClosePress}
                style={{ borderColor: theme.text.darkTurquoiseTwo, marginLeft: 24 }}
                textStyle={{ color: theme.text.darkTurquoiseTwo }}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={{ width: '100%', minWidth: 200 }}>
          <Loading />
        </View>
      )}
    </ScrollView>
  );
};

export default Prevalidation;
