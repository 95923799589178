import React, { ReactElement, useEffect, useState } from 'react';
import { MissionCardProps } from '../components/common/mission/MissionCard';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { drawerActions, DrawerOpenState } from '../store/drawerSlice';
import useCustomTheme from '../hooks/theme-hook';
import { map as Map } from '../models/helpers/platformImportHelper';
import { GetMissionsFromMapDto, MissionsClient, MysteryShopperAddressDto, MysteryShopperAddressType, MysteryShopperClient } from '../api/api.g';
import useApi from '../hooks/api-hook';
import * as Location from 'expo-location';
import Label, { LabelType } from '../components/common/Label';
import Constants from 'expo-constants';
import { Linking, Platform, Pressable, ScrollView, TouchableWithoutFeedback, useWindowDimensions, View } from 'react-native';
import useStyles from '../hooks/styles-hook';
import { MissionsHelper } from '../models/helpers/missionsHelper';
import MissionList from '../components/common/mission/MissionList';
import { MapLocation, MapMarker, MissionFilter } from '../models/MapModels';
import { useQuery } from '@tanstack/react-query';
import CreateResponsiveStyle from '../models/helpers/responsiveStyleHelper';
import { useRoute } from '@react-navigation/core';
import { navBarActions } from '../store/navBarSlice';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHouseChimney, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { userPrefsActions } from '../store/userPrefsSlice';
import { FilterItemProps } from '../components/common/filter/FilterItem';
import useTranslation from '../hooks/translation-hook';
import { missionsActions } from '../store/missionsSlice';
import { useFocusEffect, useNavigation, useNavigationState } from '@react-navigation/native';
import { LoggedState } from '../models/AuthModelsCommon';
import AuthGuard from '../components/common/AuthGuard';
import MapFilter from '../components/common/filter/MapFilter';
import MissionBranchList from '../components/common/mission/MissionBranchList';
import CustomMarkdown from '../components/common/CustomMarkDown';
import MissionMoreInfo from '../components/common/mission/MissionMoreInfo';
import { lightBoxActions } from '../store/lightBoxSlice';
import Onboarding from '../components/onboarding/Onboarding';
import useAdvertisementTrackingHook from '../hooks/advertisement-hook';
import MoreInfoButton from '../components/common/MoreInfoButton';
import { AnalyticsHelper } from '../helpers/analyticsHelper';
import { StringHelper } from '../helpers/stringHelper';
import useUserPrefsService from '../hooks/userPrefsService-hook';
import LocationPermission from '../components/map/LocationPermission';
import useSharedPopup from '../hooks/sharedPopup-hook';
import useAddressMangager from '../hooks/address-manager-hook';
import MobileAppCallToAction from '../components/common/MobileAppCallToAction';

export enum SideBarState {
  List,
  Details,
  MoreInfo,
}

export enum LocationPermissionStatus {
  Granted,
  Denied,
  Undetermined,
}

const CustomMap = () => {
  const apiMissions = useApi(MissionsClient);
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const layout = useWindowDimensions();
  const route = useRoute();
  const t = useTranslation();
  const navigation = useNavigation();
  const responsiveStyles = responsiveStyle(layout);
  const styles = useStyles((section) => section.customMap);
  const defaultPosition: MapLocation = Constants.expoConfig.extra.defautlLocation;
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((selector) => selector.auth);
  const drawerStore = useAppSelector((selector) => selector.drawer);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const missionsStore = useAppSelector((selector) => selector.missions);
  const advertisementTracking = useAdvertisementTrackingHook();
  const userService = useUserPrefsService();
  const navigationState = useNavigationState((state) => state?.routes);
  const sharedPopup = useSharedPopup();
  const addressManager = useAddressMangager();
  const [currentLocation, setCurrentLocation] = useState<MapLocation>(undefined);
  const [userLocation, setUserLocation] = useState<MapLocation>(undefined);
  const [askLocation, setAskLocation] = useState<boolean | undefined>(false);
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(false);
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [missions, setMissions] = useState<GetMissionsFromMapDto[]>();
  const [initialMissionCardProps, setInitialMissionCardProps] = useState<MissionCardProps[]>([]);
  const [missionCardProps, setMissionCardProps] = useState<MissionCardProps[]>([]);
  const [pinnedMissionCardProps, setPinnedMissionCardProps] = useState<MissionCardProps[]>([]);
  const [missionFilter, setMissionFilter] = useState<MissionFilter>();
  const [desktopContent, setDesktopContent] = useState<ReactElement>();
  const [mapComponent, setMapComponent] = useState<ReactElement>();
  const [cleanSelection, setCleanSelection] = useState(false);
  const [locationPermissionStatus, setLocationPermissionStatus] = useState<LocationPermissionStatus>();
  const [filterItems, setFilterItems] = useState<FilterItemProps[]>([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState<FilterItemProps[]>([]);
  const [showFilters, setShowFilters] = useState(true);
  const [loadingSideContent, setLoadingSideContent] = useState(true);
  const [branchMarker, setBranchMarker] = useState<string>();
  const [sideBarState, setSideBarState] = useState<SideBarState>(SideBarState.List);
  const [missionQuerySeed, setMissionQuerySeed] = useState(undefined);
  const [waitingForBrowserLocation, setWaitingForBrowserLocation] = useState(false);
  const [moreInfoTitle, setMoreInfoTitle] = useState('');
  const [moreInfoContent, setMoreInfoContent] = useState(<></>);
  const [dismissLocation, setDismissLocation] = useState(false);
  const [topLeftLocation, setTopLeftLocation] = useState<string>();
  const [bottomRightLocation, setBottomRightLocation] = useState<string>();
  const [appliedTopLeftLocation, setAppliedTopLeftLocation] = useState<string>();
  const [appliedBottomRightLocation, setAppliedBottomRightLocation] = useState<string>();
  const [openFilter, setOpenFilter] = useState(false);
  const [showedLocationPopup, setShowedLocationPopup] = useState(false);
  const [canShowOnboarding, setCanShowOnboarding] = useState(false);
  const [canShowAddressRequestPopup, setCanShowAddressRequestPopup] = useState(false);
  const [onlyAcceptableMissions, setOnlyAcceptableMissions] = useState(false);
  const [showUpcomingMissions, setShowUpcomingMissions] = useState(true);
  const [preSelectedBranch, setPreSelectedBranch] = useState<{ id: string; lat: number; lng: number }>(undefined);
  const [refreshMap, setRefreshMap] = useState(false);
  const [triggerGetUserLocation, setTriggerGetUserLocation] = useState(false);
  const [showOnARollPopup, setShowOnARollPopup] = useState(false);

  useEffect(() => {
    if (canShowOnboarding && authStore.user && (authStore.logged === LoggedState.Logged || authStore.logged === LoggedState.Anonymous)) {
      if (advertisementTracking.loaded) {
        if (advertisementTracking.showAdvertisement('Onboarding')) {
          dispatch(lightBoxActions.setContent(<Onboarding onClose={hanldeOnBoardingClose} />));
          dispatch(lightBoxActions.setVisibility(true));
        } else {
          if (authStore.logged !== LoggedState.Anonymous) {
            setCanShowAddressRequestPopup(true);
          }
        }
      }

      if (layout.width < 1200) {
        dispatch(lightBoxActions.setMobile(true));
      } else {
        dispatch(lightBoxActions.setMobile(false));
      }
    }
  }, [authStore.user, advertisementTracking.loaded, canShowOnboarding]);

  useEffect(() => {
    if (canShowAddressRequestPopup && authStore.user && (authStore.logged === LoggedState.Logged || authStore.logged === LoggedState.Anonymous)) {
      if (
        advertisementTracking.loaded &&
        userPrefs?.addresses &&
        ((userPrefs?.addresses?.length > 0 && userPrefs?.addresses?.filter((x) => x.type != MysteryShopperAddressType.Current).length === 0) ||
          userPrefs?.addresses?.length == 0)
      ) {
        addressManager.showAddressRequestPopup();
      } else if (userPrefs.addresses?.length >= 2) {
        if (showOnARollPopup) {
          sharedPopup.showOnARollPopup();
        }
      }

      if (layout.width < 1200) {
        dispatch(lightBoxActions.setMobile(true));
      } else {
        dispatch(lightBoxActions.setMobile(false));
      }
    }
  }, [authStore.user, advertisementTracking.loaded, canShowAddressRequestPopup, userPrefs, showOnARollPopup]);

  const showLocationPermissionPopup = () => {
    dispatch(
      lightBoxActions.setContent(
        <LocationPermission
          onClose={() => {
            setShowedLocationPopup(true);
            dispatch(lightBoxActions.setOnClose(undefined));
            dispatch(lightBoxActions.setVisibility(false));
          }}
        />
      )
    );
    dispatch(
      lightBoxActions.setOnClose(() => {
        setShowedLocationPopup(true);
        dispatch(lightBoxActions.setOnClose(undefined));
      })
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  useEffect(() => {
    setMissionCardProps([]);
    setPinnedMissionCardProps([]);
    setMarkers([]);
  }, []);

  const setOpenMapAsBoudaries = (expansionAmount: number, position: MapLocation) => {
    setAppliedTopLeftLocation(`${position.lat + expansionAmount * 1.5}&${position.lng - expansionAmount}`);
    setAppliedBottomRightLocation(`${position.lat - expansionAmount * 1.5}&${position.lng + expansionAmount}`);
  };

  useFocusEffect(
    React.useCallback(() => {
      if (authStore.logged === LoggedState.Logged || authStore.logged === LoggedState.Anonymous) {
        console.log('navigation end map');

        AnalyticsHelper.logScreenView(route.name).then(() => {
          console.log('Analytics', 'Page view: Map');
        });

        if (route?.params && route?.params['pos'] && route?.params['location']) {
          const mapLocation = {
            lat: parseFloat((route?.params['location'] as string).split('|')[0]),
            lng: parseFloat((route?.params['location'] as string).split('|')[1]),
          };

          setPreSelectedBranch({ id: route?.params['pos'], lat: mapLocation.lat, lng: mapLocation.lng });
          handleMarkerClickCallBack(route?.params['pos'], mapLocation.lat, mapLocation.lng);

          setCurrentLocation(mapLocation);
          setOpenMapAsBoudaries(0.15, mapLocation);
          getUserCurrentLocation(true, true);
        } else {
          getUserCurrentLocation(false, false);
        }

        setRefreshMap(true);
        dispatch(navBarActions.setVisibility(true));
        setShowOnARollPopup(missionsStore.showOnARollPopup);
        dispatch(missionsActions.setReloadRejectedValidationSeed(StringHelper.generateRandomString()));
      }
    }, [authStore.logged, route.params, missionsStore.showOnARollPopup])
  );

  useEffect(() => {
    setMissionQuerySeed(missionsStore.missionListSeed);
  }, [missionsStore.missionListSeed]);

  useEffect(() => {
    if (route) {
      dispatch(navBarActions.setPath(route.name));
    }
  }, [route]);

  useEffect(() => {
    if (missionsStore.resetMap) {
      setSideBarState(SideBarState.List);
      setCleanSelection(false);
      setMissionFilter(undefined);
      setBranchMarker(undefined);

      dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
      dispatch(missionsActions.setResetMap(false));

      dispatch(drawerActions.resetState());
    }
  }, [missionsStore.resetMap]);

  const handleBoundsChange = (top, bottom, left, right) => {
    setTopLeftLocation(`${top}&${left}`);
    setBottomRightLocation(`${bottom}&${right}`);
  };

  useEffect(() => {
    if (!branchMarker && appliedBottomRightLocation !== bottomRightLocation && appliedTopLeftLocation !== topLeftLocation) {
      const timer = setTimeout(() => {
        setAppliedTopLeftLocation(topLeftLocation);
        setAppliedBottomRightLocation(bottomRightLocation);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [topLeftLocation, bottomRightLocation, branchMarker]);

  const hanldeOnBoardingClose = (dontShowAgain) => {
    if (dontShowAgain) {
      advertisementTracking.dontShowAgain('Onboarding');
    }
    if (authStore.logged !== LoggedState.Anonymous) {
      setCanShowAddressRequestPopup(true);
    }
  };

  const mapQuery = useQuery({
    enabled:
      !!userLocation?.lat &&
      !!userLocation?.lng &&
      !apiMissions.isCancelled &&
      !!authStore.idToken &&
      !!appliedTopLeftLocation &&
      !!appliedBottomRightLocation &&
      !waitingForBrowserLocation,
    queryKey: [
      'customMap',
      'missions',
      userLocation?.lat,
      userLocation?.lng,
      selectedFilterItems,
      missionQuerySeed,
      appliedTopLeftLocation,
      onlyAcceptableMissions,
      showUpcomingMissions,
    ],
    queryFn: ({ signal }) => {
      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMissions.tokenSource?.cancel('getMissionsForMap: Query was cancelled by React Query');
        apiMissions.onCancel();
      });

      setLoadingSideContent(true);

      const promise = apiMissions.getMissionsFromMapv2(
        selectedFilterItems.map((x) => x.id),
        `${userLocation.lat}&${userLocation.lng}`,
        appliedBottomRightLocation,
        appliedTopLeftLocation,
        onlyAcceptableMissions,
        showUpcomingMissions,
        apiMissions.tokenSource?.token
      );

      return promise;
    },
    onSettled: () => {
      setLoadingSideContent(false);
    },
  });

  useEffect(() => {
    if (mapQuery.isSuccess) {
      const result = mapQuery.data;

      setMissionCardProps([]);
      setPinnedMissionCardProps([]);

      if (!result || !result.missions) {
        return;
      }

      const missionsList = result.missions
        .filter((m) => m.geocoordinate?.x !== undefined && m.geocoordinate?.y !== undefined)
        .map<MissionCardProps>((m) => {
          return MissionsHelper.mapToMissionCard(m, handleMissionPress);
        });

      const pinnedMissionsList = result.highOrderMissions
        .filter((m) => m.geocoordinate?.x !== undefined && m.geocoordinate?.y !== undefined)
        .map<MissionCardProps>((m) => {
          return MissionsHelper.mapToMissionCard(m, handleMissionPress);
        });

      setFilterItems((prev) => {
        prev = result.tags.map<FilterItemProps>((s) => {
          return {
            id: s.id,
            name: s.name === '' ? 'N/D' : s.name,
            highlight: false,
            checked: prev.findIndex((p) => p.checked && p.id === s.id) > -1,
          };
        });

        return prev.slice();
      });

      if (!filterItems || filterItems.length == 0) {
        setSelectedFilterItems([]);
      }

      setInitialMissionCardProps(missionsList);
      setMissionCardProps(missionsList);
      setPinnedMissionCardProps(pinnedMissionsList);
      setMissions(result.missions.concat(result.highOrderMissions));

      setLoadingSideContent(false);
    }
  }, [mapQuery.isSuccess, mapQuery.data]);

  useEffect(() => {
    if (navigationState && navigationState[navigationState.length - 1].name === 'map') {
      switch (sideBarState) {
        case SideBarState.List: // Showing missions list on the side bar
          setSideBarContentToFulList(missionCardProps, pinnedMissionCardProps);
          break;
        case SideBarState.Details: // Showing missions of a specific branch on the side bar or drawer
          if (branchMarker) {
            const result = missions?.find((m) => m.branchId === branchMarker);
            if (result) {
              if (layout.width > 1200) {
                setDesktopContent(
                  <MissionBranchList
                    location={currentLocation}
                    missionFromMap={result}
                    onBackPress={() => handleOnBackPress(false)}
                    onMoreInfoPress={handleReadMorePress}
                    onAdmissibilityPress={handleAdmissibilityPress}
                    onConditionsPress={handleConditionsPress}
                  />
                );
              } else {
                if (drawerStore.state === DrawerOpenState.opened) {
                  dispatch(
                    drawerActions.setContent(
                      <MissionBranchList
                        missionFromMap={result}
                        location={currentLocation}
                        onBackPress={() => handleOnBackPress(false)}
                        onMoreInfoPress={handleReadMorePress}
                        onAdmissibilityPress={handleAdmissibilityPress}
                        onConditionsPress={handleConditionsPress}
                      />
                    )
                  );
                } else {
                  dispatch(
                    drawerActions.setConfig({
                      showButtonBar: false,
                      title: `${t('Missions')}`,
                      buttonBarConfig: undefined,
                      showCloseButton: true,
                      showIcon: true,
                      icon: <FontAwesomeIcon icon={faHouseChimney} size={24} color={theme.text.primary.toString()} />,
                      state: DrawerOpenState.opened,
                      resizable: true,
                      content: (
                        <MissionBranchList
                          missionFromMap={result}
                          location={currentLocation}
                          onBackPress={() => handleOnBackPress(false)}
                          onMoreInfoPress={handleReadMorePress}
                          onAdmissibilityPress={handleAdmissibilityPress}
                          onConditionsPress={handleConditionsPress}
                        />
                      ),
                      showTitle: true,
                      onClose: () => {
                        if (layout.width > 1200) {
                          handleOnBackPress(false);
                        } else {
                          setBranchMarker(undefined);
                          setPreSelectedBranch(undefined);
                          if (Platform.OS === 'web') {
                            window.history.pushState({}, '', `/map`);
                          } else {
                            navigation.setParams({ pos: undefined, location: undefined } as any);
                          }
                        }
                      },
                    })
                  );
                }
              }
            } else {
              if (!loadingSideContent && missions?.length > 0) {
                dispatch(
                  lightBoxActions.setContent(
                    <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 16, paddingVertical: 16, maxHeight: 50 }}>
                      <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('MissionNotAvailableAnymore')}</Label>
                    </View>
                  )
                );
                dispatch(lightBoxActions.setVisibility(true));
                dispatch(
                  lightBoxActions.setOnClose(() => {
                    setBranchMarker(undefined);
                    setPreSelectedBranch(undefined);
                    dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
                    setCleanSelection(true);
                    dispatch(drawerActions.resetState());

                    if (Platform.OS === 'web') {
                      window.history.pushState({}, '', `/map`);
                    } else {
                      navigation.setParams({ pos: undefined, location: undefined } as any);
                    }
                    dispatch(lightBoxActions.setOnClose(undefined));
                  })
                );
              }
            }
          }
          break;

        case SideBarState.MoreInfo: // In case of Read More, Admimssibility and Conditions is clicked
          if (layout.width > 1200) {
            setDesktopContent(moreInfoContent);
          } else {
            if (drawerStore.state == DrawerOpenState.opened) {
              dispatch(drawerActions.setContent(moreInfoContent));
            } else {
              dispatch(
                drawerActions.setConfig({
                  showButtonBar: false,
                  title: moreInfoTitle,
                  buttonBarConfig: undefined,
                  showCloseButton: true,
                  showIcon: true,
                  icon: <FontAwesomeIcon icon={faHouseChimney} size={24} color={theme.text.primary.toString()} />,
                  state: DrawerOpenState.opened,
                  resizable: true,
                  content: <ScrollView>{moreInfoContent}</ScrollView>,
                  showTitle: true,
                  onClose: () => {
                    setCleanSelection(false);
                    handleOnBackPress(false);
                  },
                })
              );
            }
          }
          break;
      }
    }
  }, [sideBarState, branchMarker, preSelectedBranch, loadingSideContent, missions, missionCardProps, userPrefs.theme, moreInfoContent]);

  useEffect(() => {
    if (preSelectedBranch == undefined && Platform.OS !== 'web') {
      dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
    }
  }, [preSelectedBranch]);

  useEffect(() => {
    if (missionCardProps && markers) {
      if (preSelectedBranch?.id) {
        handleMarkerClickCallBack(preSelectedBranch.id, preSelectedBranch.lat, preSelectedBranch.lng);
      }

      setMapComponent(
        <Map
          mapId="missions-map"
          refreshMap={refreshMap}
          onMapRefreshed={() => {
            setRefreshMap(false);
          }}
          showUserLocation={locationPermissionGranted}
          viewList={layout.width <= 1200}
          missionCardProps={missionCardProps}
          pinnedMissionCardProps={pinnedMissionCardProps}
          referencePosition={userLocation ?? defaultPosition}
          markerItems={refreshMap ? markers.slice() : markers}
          markerClick={handleMarkerClickCallBack}
          cleanSelection={cleanSelection}
          selectedId={preSelectedBranch?.id}
          zoom={(!userLocation && !preSelectedBranch) || userLocation === defaultPosition ? 8 : 12}
          onCleanSelection={(e) => {
            if (e) {
              setSideBarState(SideBarState.List);
              setCleanSelection(false);
              setMissionFilter(undefined);
              setBranchMarker(undefined);
              setPreSelectedBranch({ id: undefined, lat: undefined, lng: undefined });

              dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
              dispatch(drawerActions.resetState());

              if (Platform.OS === 'web') {
                window.history.pushState({}, '', `/map`);
              } else {
                navigation.setParams(undefined);
              }
            }
          }}
          onPanCenter={() => {
            handleOnBackPress(true);
          }}
          onDisplayTypePress={handleMapShowList}
          onBoundsChange={handleBoundsChange}
          onFilterMissionList={(ids: string[]) => setMissionCardProps(initialMissionCardProps.filter((x) => ids?.find((id) => id == x.id)))}
        />
      );
      setCleanSelection(false);
    }
  }, [markers, cleanSelection, missionFilter, preSelectedBranch, sideBarState, refreshMap, branchMarker]);

  useEffect(() => {
    if (missions)
      handleFilters(
        filterItems.map((x) => x.id),
        true
      );
  }, [missions]);

  const useDefaultLocation = () => {
    setCurrentLocation(defaultPosition);
    setUserLocation(defaultPosition);
    setOpenMapAsBoudaries(3, { lat: defaultPosition.lat, lng: defaultPosition.lng });

    setWaitingForBrowserLocation(false);
    setAskLocation(false);
  };

  const handleMobileLocation = (location: Location.LocationObject, skipGetMissions, overridePreselectedBranch) => {
    if (location) {
      if (!skipGetMissions) {
        setCurrentLocation({ lat: location.coords.latitude, lng: location.coords.longitude });

        if (!overridePreselectedBranch) {
          setOpenMapAsBoudaries(0.3, { lat: location.coords.latitude, lng: location.coords.longitude });
        }
      }

      setUserLocation({ lat: location.coords.latitude, lng: location.coords.longitude });

      if (!overridePreselectedBranch && (!topLeftLocation || !bottomRightLocation)) {
        setOpenMapAsBoudaries(0.15, { lat: location.coords.latitude, lng: location.coords.longitude });
      }

      setWaitingForBrowserLocation(false);
      setLocationPermissionGranted(true);
      setAskLocation(false);
    }
  };

  const getMobileLastKnownLocation = (skipGetMissions, overridePreselectedBranch) => {
    Location.getLastKnownPositionAsync({ maxAge: 300000 }).then((lastKnownLocation) => {
      if (lastKnownLocation) {
        handleMobileLocation(lastKnownLocation, skipGetMissions, overridePreselectedBranch);
      } else {
        Location.getCurrentPositionAsync({
          accuracy: Location.Accuracy.Balanced,
          mayShowUserSettingsDialog: true,
          timeInterval: 10000,
        }).then((currentPosition) => {
          if (currentPosition) {
            handleMobileLocation(currentPosition, skipGetMissions, overridePreselectedBranch);
          }
        });
      }
    });
  };

  const requestionLocationPermission = () => {
    Location.requestForegroundPermissionsAsync().then(
      (result) => {
        if (!result.granted) {
          useDefaultLocation();
          return;
        }

        getUserCurrentLocation(false, false);
      },
      () => {
        setCanShowOnboarding(true);
        useDefaultLocation();
      }
    );
  };

  useEffect(() => {
    if (showedLocationPopup) {
      requestionLocationPermission();
    }
  }, [showedLocationPopup]);

  const getUserCurrentLocation = (skipGetMissions: boolean, overridePreselectedBranch: boolean) => {
    let waitingTimeout: NodeJS.Timeout;

    setUserLocation(undefined);
    setWaitingForBrowserLocation(true);

    if (Platform.OS === 'web') {
      navigator.geolocation.getCurrentPosition(
        (lastKnownLocation) => {
          if (!skipGetMissions) {
            setCurrentLocation({ lat: lastKnownLocation.coords.latitude, lng: lastKnownLocation.coords.longitude });

            if (overridePreselectedBranch) {
              setOpenMapAsBoudaries(0.15, { lat: lastKnownLocation.coords.latitude, lng: lastKnownLocation.coords.longitude });
            }
          }

          setUserLocation({ lat: lastKnownLocation.coords.latitude, lng: lastKnownLocation.coords.longitude });

          if (!overridePreselectedBranch && (!topLeftLocation || !bottomRightLocation)) {
            setOpenMapAsBoudaries(0.15, { lat: lastKnownLocation.coords.latitude, lng: lastKnownLocation.coords.longitude });
          }

          setWaitingForBrowserLocation(false);
          setLocationPermissionGranted(true);
          setAskLocation(false);
          setCanShowOnboarding(true);
        },
        (err) => {
          console.warn(`ERROR(${err.code}): ${err.message}`);

          useDefaultLocation();
          setCanShowOnboarding(true);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 300000,
        }
      );
    } else {
      (async () => {
        Location.getForegroundPermissionsAsync().then((result) => {
          if (result.granted) {
            setLocationPermissionStatus(LocationPermissionStatus.Granted);
            setCanShowOnboarding(true);
            getMobileLastKnownLocation(skipGetMissions, overridePreselectedBranch);
          } else {
            if (result.status == 'undetermined') {
              setLocationPermissionStatus(LocationPermissionStatus.Undetermined);

              if (result.canAskAgain) {
                showLocationPermissionPopup();
              }
            } else {
              if (result.status == 'denied') {
                if (result.canAskAgain) {
                  showLocationPermissionPopup();
                } else {
                  setWaitingForBrowserLocation(false);
                  setLocationPermissionStatus(LocationPermissionStatus.Denied);
                  setLocationPermissionGranted(false);
                  setCanShowOnboarding(true);
                  useDefaultLocation();
                }
              }
            }
          }
        });
      })();
    }

    return () => (waitingTimeout ? clearInterval(waitingTimeout) : undefined);
  };

  useEffect(() => {
    if (currentLocation) {
      dispatch(userPrefsActions.setLocation(currentLocation));

      const dto = new MysteryShopperAddressDto();
      dto.latitude = currentLocation.lat;
      dto.longitude = currentLocation.lng;
      dto.type = MysteryShopperAddressType.Current;

      apiMysteryShopper
        .updateCurrentAddress(dto, apiMysteryShopper.tokenSource?.token)
        .then(() => {
          userService.updateUserProfileInfo();
        })
        .catch((error) => {
          console.log('Error', error);
        });
    }
  }, [currentLocation]);

  useEffect(() => {
    if (triggerGetUserLocation) {
      getUserCurrentLocation(false, true);
      setTriggerGetUserLocation(false);
    }
  }, [triggerGetUserLocation]);

  const handleMapShowList = (showList: boolean) => {
    console.log('filter', !showList);
    setShowFilters(!showList);
  };

  const handleMissionPress = (e: MissionCardProps) => {
    setSideBarState(SideBarState.Details);
    setBranchMarker(e.id);
    setMissionFilter({ branchId: e.id });
    setCleanSelection(false);

    dispatch(missionsActions.setSelectedBranch({ id: e.id, lat: e.geocoordinate.y, lng: e.geocoordinate.x }));
  };

  const handleOnBackPress = (overrideSideBarState: boolean) => {
    if (overrideSideBarState || sideBarState === SideBarState.Details) {
      if (overrideSideBarState) {
        if (preSelectedBranch) {
          setRefreshMap(true);
        }

        setTriggerGetUserLocation(true);
      }

      setBranchMarker(undefined);

      setPreSelectedBranch(undefined);
      dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));

      setSideBarContentToFulList(missionCardProps, pinnedMissionCardProps);
      setCleanSelection(true);
      dispatch(drawerActions.resetState());

      if (Platform.OS === 'web') {
        window.history.pushState({}, '', `/map`);
      } else {
        navigation.setParams(undefined);
      }
    }
  };

  const setSideBarContentToFulList = (missionsList: MissionCardProps[], pinnedMissionsList: MissionCardProps[]) => {
    setDesktopContent(
      <View style={[responsiveStyles('sideBarContent'), { backgroundColor: theme.background.primary }]}>
        <MissionList missionCards={missionsList} pinnedMissionCards={pinnedMissionsList} loadingSpin={loadingSideContent} />
      </View>
    );
  };

  const handleMoreInfoBack = () => {
    setSideBarState(SideBarState.Details);
  };

  const handleReadMorePress = (info: string) => {
    setMoreInfoTitle('Scenario');
    setMoreInfoContent(
      <MissionMoreInfo title={'Scenario'} onBackPress={handleMoreInfoBack}>
        <CustomMarkdown>{info}</CustomMarkdown>
      </MissionMoreInfo>
    );
    setSideBarState(SideBarState.MoreInfo);
  };

  const handleAdmissibilityPress = (info: string) => {
    setMoreInfoTitle(t('Admissibility'));
    setMoreInfoContent(
      <MissionMoreInfo title={t('Admissibility')} onBackPress={handleMoreInfoBack}>
        <CustomMarkdown>{info}</CustomMarkdown>
      </MissionMoreInfo>
    );
    setSideBarState(SideBarState.MoreInfo);
  };

  const handleConditionsPress = (info: string) => {
    setMoreInfoTitle(t('Terms-and-Conditions'));
    setMoreInfoContent(
      <MissionMoreInfo title={t('Terms-and-Conditions')} onBackPress={handleMoreInfoBack}>
        <CustomMarkdown>{info}</CustomMarkdown>
      </MissionMoreInfo>
    );
    setSideBarState(SideBarState.MoreInfo);
  };

  const handleMarkerClickCallBack = (id: string, lat: number, lng: number) => {
    setBranchMarker(id);
    dispatch(missionsActions.setSelectedBranch({ id: id, lat: lat, lng: lng }));
    setSideBarState(SideBarState.Details);
  };

  const handleFilters = (filters: string[], forceUpdate: boolean = false) => {
    setSelectedFilterItems(filterItems.filter((x) => x.checked));
    setSideBarState(SideBarState.List);

    // if (Platform.OS === 'web' || forceUpdate) {
    updateMarkers(filters);
    // }
  };

  const updateMarkers = (filters: string[]) => {
    if (missions) {
      let filterValues = [];

      if (filters && filters.length > 0) {
        filterValues = filterItems.filter((f) => filters.indexOf(f.id) > -1).map((f) => f.name);
      } else {
        filterValues = filterItems.map((f) => f.name);
      }

      setMarkers(
        missions
          .filter((x) => {
            const hasCoordinates = x.geocoordinate != undefined;
            let withTag: boolean = true;

            if (filters) {
              withTag = filterValues.indexOf(x.tags) > -1;
            }

            return hasCoordinates && withTag;
          })
          .map((mi) => {
            const { icon, faIcon } = MissionsHelper.getMissionIcon(mi.tags);

            return {
              title: mi.name,
              id: mi.branchId,
              selected: false,
              coordinate: { latitude: mi.geocoordinate?.y ?? defaultPosition.lat, longitude: mi.geocoordinate?.x ?? defaultPosition.lng },
              svgIcon: mi.svgIcon ?? icon,
              faIcon: faIcon,
              seed: StringHelper.generateRandomString(),
              defaultPin: authStore.logged === LoggedState.Anonymous || mi.canAccept ? 'default' : 'grey',
            };
          })
      );
    }
  };

  return (
    <AuthGuard>
      {true && (
        <TouchableWithoutFeedback
          onPress={() => {
            setOpenFilter(false);
          }}
        >
          <View style={responsiveStyles('container')}>
            <MobileAppCallToAction />
            <View style={responsiveStyles('mapContainer')}>
              {locationPermissionStatus === LocationPermissionStatus.Denied &&
                !dismissLocation &&
                !askLocation &&
                currentLocation &&
                !locationPermissionGranted &&
                !waitingForBrowserLocation && (
                  <Pressable
                    style={styles.permissionOverlay}
                    onPress={() => {
                      if (Platform.OS === 'web') {
                        getUserCurrentLocation(false, false);
                      } else {
                        Linking.openSettings();
                      }
                    }}
                  >
                    <MoreInfoButton title={''} size={16} color="white" style={{ marginRight: 8 }} openURL={'location-lanla-website-support-link'}>
                      {`${t('help.location-support-retry')}`}
                    </MoreInfoButton>
                    <Label variant={LabelType.titleWhiteSmall}>{t('not_allowed_location')}</Label>
                    <Pressable
                      onPress={() => {
                        setDismissLocation(true);
                      }}
                      style={{ marginLeft: 8, alignSelf: 'center' }}
                    >
                      <FontAwesomeIcon icon={faXmark} size={16} style={styles['permissionOverlay.help.icon']} />
                    </Pressable>
                  </Pressable>
                )}
              {locationPermissionStatus === LocationPermissionStatus.Undetermined &&
                !dismissLocation &&
                !askLocation &&
                currentLocation &&
                !locationPermissionGranted &&
                !waitingForBrowserLocation && (
                  <Pressable
                    style={styles.permissionOverlay}
                    onPress={() => {
                      if (Platform.OS === 'web') {
                        getUserCurrentLocation(false, false);
                      } else {
                        Linking.openSettings();
                      }
                    }}
                  >
                    <MoreInfoButton title={''} size={16} color="white" style={{ marginRight: 8 }} openURL={'location-lanla-website-support-link'}>
                      {`${t('help.location-support-retry')}`}
                    </MoreInfoButton>
                    <Label variant={LabelType.titleWhiteSmall}>{t('not_able_location_information')}</Label>
                    <Pressable
                      onPress={() => {
                        setDismissLocation(true);
                      }}
                      style={{ marginLeft: 8, alignSelf: 'center' }}
                    >
                      <FontAwesomeIcon icon={faXmark} size={16} style={styles['permissionOverlay.help.icon']} />
                    </Pressable>
                  </Pressable>
                )}
              {!dismissLocation && showFilters && !waitingForBrowserLocation && !userLocation && locationPermissionGranted && (
                <Pressable
                  style={styles.permissionOverlay}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      window.location.reload();
                    } else {
                      getUserCurrentLocation(false, false);
                    }
                  }}
                >
                  <MoreInfoButton title={''} size={16} color="white" style={{ marginRight: 8 }} openURL={'location-lanla-website-support-link'}>
                    {`${t('help.location-support-retry')}`}
                  </MoreInfoButton>
                  <Label style={{ flexShrink: 1 }} variant={LabelType.titleWhiteSmall}>
                    {t('location_retry')}
                  </Label>
                  <Pressable
                    onPress={() => {
                      setDismissLocation(true);
                    }}
                    style={{ marginLeft: 8, alignSelf: 'center' }}
                  >
                    <FontAwesomeIcon icon={faXmark} size={16} style={styles['permissionOverlay.help.icon']} />
                  </Pressable>
                </Pressable>
              )}
              {!dismissLocation && showFilters && waitingForBrowserLocation && (
                <View style={styles.permissionOverlay}>
                  <MoreInfoButton title={''} size={16} color="white" style={{ marginRight: 8 }} openURL={'location-lanla-website-support-link'}>
                    {`${t('help.location-support-retry')}`}
                  </MoreInfoButton>
                  <Label numberOfLines={3} variant={LabelType.titleWhiteSmall}>
                    {t('looking_for_position')}
                  </Label>
                  <Pressable
                    onPress={() => {
                      setDismissLocation(true);
                    }}
                    style={{ marginLeft: 8, alignSelf: 'center' }}
                  >
                    <FontAwesomeIcon icon={faXmark} size={16} style={styles['permissionOverlay.help.icon']} />
                  </Pressable>
                </View>
              )}
              {!currentLocation && (
                <View style={styles.permissionOverlay}>
                  <MoreInfoButton title={''} size={16} color="white" style={{ marginRight: 8 }} openURL={'location-lanla-website-support-link'}>
                    {`${t('help.location-support-retry')}`}
                  </MoreInfoButton>
                  <Label numberOfLines={3} variant={LabelType.titleWhiteSmall}>
                    {t('check-location-permission')}
                  </Label>
                </View>
              )}
              {mapComponent}
              {showFilters && (
                <MapFilter
                  items={filterItems}
                  onlyAcceptableMissions={onlyAcceptableMissions}
                  showUpcomingMissions={showUpcomingMissions}
                  onChangeText={() => {}}
                  onClear={() => {}}
                  onFocus={() => {}}
                  onBlur={() => {}}
                  onOpenChange={setOpenFilter}
                  onKeyPress={() => {}}
                  onBack={() => {}}
                  onSelectionChange={handleFilters}
                  onSearchAreaPress={() => {
                    setAppliedTopLeftLocation(topLeftLocation);
                    setAppliedBottomRightLocation(bottomRightLocation);
                  }}
                  showSearchArea={topLeftLocation !== appliedTopLeftLocation}
                  open={openFilter}
                  loading={loadingSideContent}
                  onOnlyAcceptableMissions={() => setOnlyAcceptableMissions((prev) => !prev)}
                  onShowUpcomingMissions={() => setShowUpcomingMissions((prev) => !prev)}
                />
              )}
            </View>
            {layout.width > 1200 && (
              <ScrollView style={responsiveStyles('sideBar')} contentContainerStyle={{ flexGrow: 1 }}>
                {desktopContent}
              </ScrollView>
            )}
          </View>
        </TouchableWithoutFeedback>
      )}
    </AuthGuard>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    mapContainer: {
      flexGrow: 1,
      height: '100%',
    },
    sideBar: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 350,
      width: '30%',
      height: '100%',
      flexGrow: 1,
      backgroundColor: 'white',
    },
    sideBarTitle: {
      borderBottomWidth: 1,
      borderBottomColor: '#e9e9e980',
      padding: 16,
    },
    sideBarContent: {
      flexGrow: 1,
      flexShrink: 1,
      height: '100%',
    },
  },
  {
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    mapContainer: {
      width: '100%',
      height: '100%',
    },
    sideBar: {
      width: 0,
      height: '100%',
      flexGrow: 1,
    },
  }
);

export default CustomMap;
